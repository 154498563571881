<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="groupeData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="4">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Succursale
            </h6>
          </b-col>

          <b-col cols="8">
            <b-button
              v-if="$router.name == 'groupe-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'groupe-view',
                  params: { id: groupeData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'groupe-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'groupe-view',
                  params: { id: groupeData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'groupe-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste des Succursales</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="invoice-add">
          <!-- Col: Left (Invoice Container) -->
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-row class="">
                <!-- Name -->
                <b-col cols="12" md="12">
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="Nom / Designation du Succursale"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="groupeData.name"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Bureau Regional de Beni,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le nom s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="">
                <!-- country -->
                <b-col cols="6" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="country"
                    rules="required"
                  >
                    <b-form-group label="Pays" label-for="country">
                      <b-form-input
                        id="country"
                        v-model="groupeData.country"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: RDC,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le nom du pays s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- country -->
                <b-col cols="6" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="state"
                    rules="required"
                  >
                    <b-form-group label="Province" label-for="state">
                      <b-form-input
                        id="state"
                        v-model="groupeData.state"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Nord Kivu,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le nom de la province s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- town -->
                <b-col cols="6" md="6">
                  <validation-provider
                    #default="validationContext"
                    name="town"
                    rules="required"
                  >
                    <b-form-group label="Ville" label-for="town">
                      <b-form-input
                        id="town"
                        v-model="groupeData.town"
                        :state="getValidationState(validationContext)"
                        trim
                        placeholder="Ex: Beni,..."
                        class=""
                      />
                      <b-form-invalid-feedback>
                        Completer le nom de ville s'il vous plait
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- PERMISSION TABLE -->
        <b-card no-body class="border mt-1">
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon icon="LockIcon" size="18" />
              <span class="align-middle ml-50">Administrateur</span>
            </b-card-title>
          </b-card-header>
          <b-card no-body class="invoice-preview-card">
            <b-row class="p-10 p10" style="padding: 10px">
              <!-- country -->
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="director"
                  rules="required"
                >
                  <b-form-group
                    label="Le gestionnaire regionale principal"
                    label-for="director"
                  >
                    <b-form-input
                      id="director"
                      v-model="groupeData.director"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Ex: SKU-002"
                      class=""
                    />
                    <b-form-invalid-feedback>
                      Compléter le code de l'Administrateur du Succursale
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
        </b-card>

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="groupeData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BMedia,
  BAvatar,
  BTable,
  BCardHeader,
  BCardTitle,

  // Form Validation
  BFormInvalidFeedback,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "groupe";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo,

    BTable,
    BCardHeader,
    BCardTitle,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      showed: true,
      loading: false,
    };
  },
  props: {
    groupeData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    // console
    if (router.currentRoute.name == "groupe-add-new") {
      this.operationTitle = "Nouveau ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "groupe-view") {
      this.operationTitle = "Détails du ";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "groupe-edit") {
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;

      //
      // empty values
      // this.$props.groupeData.name = "";
      // this.$props.groupeData.permissions = [];
      // //
      // this.$props.groupeData.groupes.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    reinitForm() {
      //
      this.$props.groupeData.name = "";
      // remove all occurences
      // initialise 1 element
      // this.$props.groupeData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    formReadyForSubmission() {
      // validation check condition
      this.errors = [];
      if (this.$props.groupeData.name == "") {
        this.errors.push("Compléter le nom du groupe svp.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    async addUpdateItemAction() {
      // console.log(this.permissionsData);

      //////////////////////////////////////////////////////////////
      let formData = new FormData();

      if (this.formReadyForSubmission() == false) {
        // console.log(this.refFormObserver.fields);
        return;
      }

      //   alert("ok");
      //   return;

      const defaultActionText = this.actionName;

      let url = "";
      if (router.currentRoute.name == "groupe-add-new") {
        this.actionName = "Enregistrement...";
        url = $themeConfig.app.api_endpoint + "add_" + moduleName;
      }
      if (router.currentRoute.name == "groupe-view") {
        url = $themeConfig.app.api_endpoint + "view_" + moduleName;
      }
      if (router.currentRoute.name == "groupe-edit") {
        this.actionName = "Modification...";
        url = $themeConfig.app.api_endpoint + "update_" + moduleName;
        // convert bool to int
      }

      // finalise form data
      // delete this.$props.moduleData.image_url;
      formData = this.$props.groupeData;

      await axios
        .post(url, formData)
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "groupe-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          // console.log(error);

          this.actionName = defaultActionText;
          
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "groupe-view" });
    },
    goToNextItem() {
      router.push({ name: "groupe-list" });
    },
    goToList() {
      router.push({ name: "groupe-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-groupe";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.groupeData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);
    //
    // let itemsList = ref([]);
    // store.dispatch("app-groupe/fetchItems").then((response) => {
    //   itemsList.value = response.data;
    //   // itemsList = response.data;
    // });

    // fill items
    // let itemsOptions = itemsList;

    let typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    const groupeOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    // const permissionsData = [
    //   {
    //     module: "Super Admin",
    //     creation: true,
    //     lecture: true,
    //     modification: false,
    //     suppression: true,
    //   },
    //   {
    //     module: "Superviseur",
    //     creation: false,
    //     lecture: true,
    //     modification: true,
    //     suppression: true,
    //   },
    //   {
    //     module: "Author",
    //     creation: false,
    //     lecture: true,
    //     modification: true,
    //     suppression: false,
    //   },
    //   {
    //     module: "Contributor",
    //     creation: true,
    //     lecture: false,
    //     modification: false,
    //     suppression: false,
    //   },
    //   {
    //     module: "User",
    //     creation: false,
    //     lecture: true,
    //     modification: true,
    //     suppression: true,
    //   },
    // ];

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      // itemsList,
      // itemsOptions,
      paymentMethods,
      refFormObserver,
      getValidationState,
      resetForm,
      // permissionsData,
      typeOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
